import '@style/components/footer.scss'

const info = [
    {key:'대표이사', val: '박주영'},
    {key:'주소', val: '서울 강남구 테헤란로22길 15, 2층'},
    {key:'사업자등록번호', val: '118-86-02981'},
]

const Footer = () => {
    return (
        <footer>
            <div className='company'>
                <div className='logo'>
                    <img src='image/logo.svg' alt='logo'/>
                </div>
            </div>
            <ul className='info'>
                <li>(주)이쿠아</li>
                {
                    info.map((data,index)=> (
                        <li key={index}>
                            <span className='key'>{data.key}</span>
                            <span>{data.val}</span>
                        </li>
                    ))
                }
            </ul>
        </footer>
    );
};

export default Footer;