import { useEffect } from "react"

const Typo = (props) => {
    useEffect(() => {
        var items = document.querySelectorAll('.typo_wrapper .title');
        var observer = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                } else {
                    entry.target.classList.remove('animate');
                }
            });
        }, { threshold: 0.5 }); 
        items.forEach(function(item) {
            observer.observe(item);
        });
    },[])
    return (
        <div className={props.className + " typo_wrapper "}>
            <p className="icon">{props.icon}</p>
            <p className="fs-m desc">{props.desc}</p>
            <p className="fs-l title">{props.title}</p>
        </div>
    )
}

export default Typo