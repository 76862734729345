import { useState } from "react";
import { type as data } from "@/data/index";
import Student from "./Student";
import Business from "./Business";
const Type = () => {
    const [type, setType] = useState('')
    return (
        <>        
        <div className="type_wrapper">
            <p className="title fs-l">{data.main}</p>
            <div className="types">
                <div className="box">
                    <div className="img">
                        <img src="/image/tuti_student.svg"/>
                    </div>
                    <p className="fs-l title">{data.student_title}</p>
                    <p className="fs-m desc">{data.student_desc}</p>
                    <p className="button" onClick={() => setType('student')}>자세히 알아보기</p>
                </div>
                <div className="box">
                    <div className="img">
                        <img src="/image/tuti_business.svg"/>
                    </div>
                    <p className="fs-l title">{data.business_title}</p>
                    <p className="fs-m desc">{data.business_desc}</p>
                    <p className="button" onClick={() => setType('business')}>자세히 알아보기</p>
                </div>
            </div>
        </div>
         {
            type === 'student' ?
            <Student/>
            : type === 'business' ?
                <Business/> : ''
         }
        </>
    );
};

export default Type;