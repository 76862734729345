import { useEffect } from "react";
import Main from "@/components/layout/Main"
import Type from "@/components/layout/Type"
import Ask from "@/components/layout/Ask"
import "@/assets/style/pages/home.scss"

const Home = () => {
  
    useEffect(() => {})
    return (
        <div className="wrapper wrap_home">
            <Main/>
            <Type/>
            <Ask />
        </div>
    )
}

export default Home;