import { team } from "@/data";
import "@/assets/style/pages/team.scss"

const Team = () => {
    return(
        <div className="team_wrapper">
            <div className="intro">
                <p className="fs-m">who we are</p>
                <p>{team.text}</p>
            </div>
            <div className="team">
                <p className="fs-l">Team</p>
                <div className="box_list">
                    {
                        team.member.map((v,i) => (
                            <div key={i}>
                                <div className="box">
                                    <p>{v.name}</p>
                                    <p>{v.major}</p>
                                </div>
                            </div>
                        ))
                    }
                </div> 
            </div>
        
        </div>
    )
}

export default Team;