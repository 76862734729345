import { business } from "@/data/index";

const Business = () => {
    return (
        <div className="business_wrapper">
            <div className="summary">
                <div className="logo">
                    <img src="/image/logo.svg"/>
                </div>
                <p className="fs-l">{business.page_1.title}</p>
                <div className="box_list">
                    <p className="box" dangerouslySetInnerHTML={{ __html: business.page_1.card_1 }}></p>
                    <p className="box" dangerouslySetInnerHTML={{ __html: business.page_1.card_2 }}></p>
                    <p className="box" dangerouslySetInnerHTML={{ __html: business.page_1.card_3 }}></p>
                </div>
            </div>
            <div className="description">
                <p dangerouslySetInnerHTML={{ __html: business.page_2.desc }}></p>
            </div>
            <div className="reason">
                <p className="fs-l shadow title">{business.page_3.title}</p>
                <p className="fs-m desc">{business.page_3.desc}</p>

                <table className="table">
                    <tbody>
                    <tr key="icon">
                        <th></th>
                        <th>
                            <p className="shadow">정규직 2명</p>
                            <div className="logo">
                                <img src="/image/man1.svg"/>
                                <img src="/image/man1.svg"/>
                            </div>
                        </th>
                        <th className="target">
                            <p className="icon">🎯</p>
                            <p className="shadow">정직원 1명<br/>트티 1명</p>
                            <div className="logo">
                                <img src="/image/man1.svg"/>
                                <img src="/image/tuti_student.svg"/>
                            </div>
                        </th>
                        <th>
                            <p className="shadow">정규직 1명<br/>아웃소싱</p>
                            <div className="logo">
                                <img src="/image/man1.svg"/>
                                <img src="/image/man2.svg"/>
                            </div>
                        </th>
                    </tr>
                    <tr className="unvisible" key="unvisible"></tr>
                    {
                        business.page_4.map((v,i) => (
                            <tr key={i}>
                                {
                                    v.map((w,j) => (
                                        <td key={j}>{w}</td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Business