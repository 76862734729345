
const Ask = () => {
    return (
        <div className="ask_wrapper">
            <p className="fs-l">모든 궁금증을 환영합니다!</p>
            <div className="ask">
                <p className="fs-l notice"> ☎ 언제든지 편하게 '문의하기’를 클릭하세요.</p>
                <div>
                    <div className="form">
                        <div className="input">
                            <label htmlFor='name' className="fs-l">성함/기업명</label>
                            <input type="text" id="name"/>
                        </div>
                        <div className="input">
                            <label htmlFor='tel' className="fs-l">연락처</label>
                            <input type="text" id="tel"/>   
                        </div>
                        <div className="input">
                            <label htmlFor='content' className="fs-l">내용</label>
                            <input type="text" id="content"/>
                        </div>
                    </div>
                    <p className="fs-l agreement">개인정보 수집 및 이용 동의</p>
                    <div className="button">문의하기</div>
                </div>
            </div>
        </div>
    )
}

export default Ask