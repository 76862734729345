import { main } from "@/data/index";
import Youtube from "react-youtube"

const Main = () => {
    const youtube = {
        width: "100%",
        height: "230px",
        playerVars: {
         autoplay: 0, //자동 재생 여부 
         modestbranding: 1, //컨트롤 바에 유튜브 로고 표시 여부
        }
    }
    return (
        <div className="main_wrapper">
            <div className="upper">
                <div className="tuti_main_msg">
                    <p className="fs-l main">{main.main}</p>
                    <p className="fs-m sub">{main.sub}</p>
                    <Youtube videoId="qkcoaV3WLJo" opts={youtube} className="youtube" />
                </div>
                <img src="/image/tuti_icon.svg" className="tuti_icon"/>
            </div>
            <div className="ocean">
                <div className="wave2"></div>
                <div className="wave1"></div>
            </div>
        </div>
    )
}

export default Main