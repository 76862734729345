export const main = {
    main: "대학생의 미래를 함께\n만들어가는 곳, 트티!",
    sub: "똑똑한 대학생을 위한 직무교육 및 인턴 매칭 플랫폼"
}

export const type = {
    main: `“사회 경험의 장벽을 낮추는 트티!\n여러분의 성공을 위해 항상 도와드립니다.”`,
    student_title: "커리어탐색자",
    student_desc: "트티에서 다양한 진로를 탐색하고\n직무 경험을 쌓아보세요!",
    business_title: "기업 담당자",
    business_desc: "검증된 인재를 빠르게 찾을 수 있습니다!\n함께 회사를 발전시켜보세요!"
}

export const student = {
    page_1: {
        text_1: "내가 선택한 길이 정말 맞는 걸까? 😣\n 이 일을 평생 하고 싶을까? 😥\n 이 일을 잘 할 수 있을까?😫",
        text_2: "트티와 함께 진로고민 불안을 자신감으로 바꿔봐!",
        card_1:"개인화된\n<em>퍼스널 브랜딩</em>서비스",
        card_2:"인턴연계형\n<em>교육 프로그램</em>",
        card_3:"학기중 가능한\n<em>인턴 매칭</em>",
        card_4:"대학생\n관심사 별\n<em>스터디/모임</em>",
        card_5:"취직한 선배\n<em>서포터즈 매칭</em>",
        card_6:"커리큘럼 완수\n<em>트티 검증</em>\n<em>스펙 제공</em>",
    },
    page_2: {
        text_1: [48, "트티와 함께하는 기업"],
        text_2: [504, "트티와 함께하고 있는 대학생"],
        text_3: ["100%", "의뢰자 수행자 만족도"],
        text_4: "오직 트티에서만,",
        text_5: `"사회경험이\n곧\n스펙이다!"`
    },
    page_3: {
        icon: "🤯",
        desc: "인생 고민, 진로 고민 !\n미래에 대한 확신이 들지 않을 때!",
        title: "트티 퍼스널 브랜딩 서비스"
    },
    page_4: {
        icon: "🤑",
        desc: "과외는 안잡혀서 알바로 돈벌어야하는데..\n사무직으로 돈도 벌고 회사경험도 쌓고 싶을 때!",
        title: "트티에만 있는 학기중 인턴"
    },
    page_5: {
        icon: "👨‍💻",
        desc: "당신의 관심사를 공유할 동료를 찾고 있다면?\n다양한 관심사에 맞춘 스터디와 모임에 참여하고 싶을 때!",
        title: "트티 검증 스터디/ 모임"
    },
    page_6: {
        icon: "👩‍🏫",
        desc: "당신의 고민을 이해하는 선배와 함께,\n더 빠르고 확실한 미래를 향해 나아가고 싶을 때",
        title: "관련 직무 서포터즈 매칭"
    },
    page_7: {
        icon: "🏅",
        desc: "자신의 능력을 검증할 수 있는 방법!\n트티와 함께 오늘도 갓생 레벨 업 할 때",
        title: "트티 검증 스펙 제공"
    },
}

export const business = {
    page_1: {
        title: "채용 및 인력 관리, 기업의 시간과 비용 부분을\n트티가 획기적으로 줄여드립니다!",
        card_1: "대학생에게\n<em>기업 홍보\n서비스</em>\n\n-영상제작\n-트티 앱 광고",
        card_2: "기업별 제시\n과제 수행후\n<em>우수 인턴\n채용 서비스</em>\n\n-실력있는\n인재 매칭",
        card_3: "<em>피드백 서비스</em>\n\n-교육과정,\n인턴과정 동안\n지속적인\n트티 매니저의\n서포트"
    },
    page_2 :{
        desc: `“<em>더 중요한 일에 집중하실 수 있도록,</em>\n트티가 대표님들의 고민을 해결해드립니다.\n<em>유동적이고 번거로운 일에 적합한 인재를 인턴</em>으로 찾아드립니다.\n<em>미리 할 일을 숙지한 우수한 인재를 채용</em>하시면,\n<em>회사는 빠르게 성장</em>할 수 있습니다.\n<em>트티와 함께라면, 대표님들의 성공이 보장</em>됩니다!” 😊`
    },
    page_3: {
        title: "트티에서 검증한 인턴을 채용해야 하는 이유",
        desc: `회사가 발전 할 수록 유동적이고 번거로운 일은 늘어나고
        적합한 인재 찾는 과정은 복잡해지는 지금 트티에서 인재를 만나보세요!`,
    },
    page_4: [
        ["책임", "애매한 책임 분할", "확실한 업무 분담", " 아웃소싱 업체 관리 번거로움"],
        ["인하우스","인하우스","인하우스/ 비대면","프리랜서와 소통이 힘듬"],
        ["업무숙지","사수가 가르침/ 스스로 학습","직무 교육으로 미리 숙지함","정규직 또는 대표가 직접관리"],
        ["채용비용","정규직 2명의 월급","정규직/ 인턴 월급","정규직 1명 / 아웃소싱 외주비"],
        ["채용시간","서류평가/ 면접 등 시간 소요","트티에서 바로 매칭","지인 소개 및 검토시간 소요"],
    ]
}

export const team = {
    text : `
(주)이쿠아는 넓은 세상을 
모두에게 체험시키는 것을 목표로 합니다.
모든 연령대의 사람들이 세상을 더 잘 이해하고, 
그들의 삶을 풍요롭게 만드는 데 도움이 되기를 바랍니다. `,
    member: [
        {name: "Natalie", major: "CEO"},
        {name: "Heather", major: "CFO"},
        {name: "Sally", major: "PM "},
        {name: "Song", major: "TM"},
        {name: "Alan", major: "TP"},
    ]

}