import '@style/components/header.scss'


const Header = () => {
    return (
        <header>
            <div className='tuti'>
                <a className='logo' href='/'>
                    <img src='image/logo.svg' alt='logo'/>
                </a>
                <div className='name'>
                    <p>트티</p>
                    <p>Welcome to tuti</p>
                </div>
            </div>
            <div className='menu'>
                <a href='https://www.instagram.com/tuti_platform/'>instagram</a>
                <a href='https://www.youtube.com/@user-oj7vf4or2v'>youtube</a>
                <a href='https://blog.naver.com/tuti170000'>blog</a>
                <a href='https://cafe.naver.com/tutiplatform'>cafe</a>
                <a href='/team'>team</a>
            </div>
        </header>
    );
};

export default Header;