import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Home, Team } from "@/pages";
import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/team" element={<Team/>} />
          </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;