import { useEffect } from "react";
import Typo from "../Typo"
import { student } from "@/data/index";

const Student = () => {
    const mounted = () => {
        var items = document.querySelectorAll('.box_list .box');
        var observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry, i) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        entry.target.style.opacity = 1;
                    }, i*500);
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.5 }); 
    
        items.forEach(function(item) {
            observer.observe(item);
        });
    }
    useEffect(() => {
        mounted()
    }, [])
    return (
        <div className="student_wrapper">
            <div className="summary">
                <div className="logo">
                    <img src="/image/logo.svg" alt="logo"/>
                </div>
                    <p className="desc">{student.page_1.text_1}</p>
                    <p className="fs-l text">{student.page_1.text_2}</p>

                    <div className="box_list">
                        <p className="box fs-l p_100" dangerouslySetInnerHTML={{ __html: student.page_1.card_1 }}></p>
                        <p className="box fs-l p_200" dangerouslySetInnerHTML={{ __html: student.page_1.card_2 }}></p>
                        <p className="box fs-l p_200" dangerouslySetInnerHTML={{ __html: student.page_1.card_3 }}></p>
                    </div>
                    <div className="box_list">
                        <p className="box fs-l p_400" dangerouslySetInnerHTML={{ __html: student.page_1.card_4 }}></p>
                        <p className="box fs-l p_500" dangerouslySetInnerHTML={{ __html: student.page_1.card_5 }}></p>
                        <p className="box fs-l p_600" dangerouslySetInnerHTML={{ __html: student.page_1.card_6 }}></p>
                    </div>
            </div>
            <div className="background_icon">
                <div className="number_list">
                    <div className="number">
                        <p className="fs-l">{student.page_2.text_1[0]}</p>
                        <p className="fs-m">{student.page_2.text_1[1]}</p>
                    </div>
                    <div className="number">
                        <p className="fs-l">{student.page_2.text_2[0]}</p>
                        <p className="fs-m">{student.page_2.text_2[1]}</p>
                    </div>
                    <div className="number">
                        <p className="fs-l">{student.page_2.text_3[0]}</p>
                        <p className="fs-m">{student.page_2.text_3[1]}</p>
                    </div>
                </div>
                <div className="message">
                    <p>{student.page_2.text_4}</p>
                    <p>{student.page_2.text_5}</p>
                    <img src="image/background_icon.svg" alt="background"/>
                </div>
            </div>
            <Typo icon={student.page_3.icon} desc={student.page_3.desc} title={student.page_3.title} className="typo_1"></Typo>
            <Typo icon={student.page_4.icon} desc={student.page_4.desc} title={student.page_4.title} className="typo_2"></Typo>
            <Typo icon={student.page_5.icon} desc={student.page_5.desc} title={student.page_5.title} className="typo_3"></Typo>
            <Typo icon={student.page_6.icon} desc={student.page_6.desc} title={student.page_6.title} className="typo_4"></Typo>
            <Typo icon={student.page_7.icon} desc={student.page_7.desc} title={student.page_7.title} className="typo_5"></Typo>
        </div>
    )
}

export default Student